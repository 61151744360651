import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"
import tree from "../images/404tree.svg"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

const PaymentSuccessPage = () => (
  <SharedStateProvider>
    <Layout language="en">
      <SEO
        title="Payment Successful"
        description="Payment Successful"
        robots="noindex"
      />
      <div className="joshua-tree-content">
        <div className="columns top-section text-section white-back is-vcentered">
          <div className="column is-3"></div>
          <div className="column">
            <div className="columns">
              <div className="column has-text-centered-mobile">
                <h1 className="four04-heading">Your Payment Was Successful</h1>
                <p className="large">
                  Thank you for your payment. Please contact our office if you
                  have any questions.
                </p>
                <div className="columns has-text-centered-mobile">
                  <div className="column">
                    <Button
                      contained
                      buttonText="Contact"
                      href="/contact-us/"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-3"></div>
        </div>
      </div>
    </Layout>
  </SharedStateProvider>
)

export default PaymentSuccessPage
